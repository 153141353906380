export const ASPECT_RATIO = 1.3;

export const DEFAULT_MED_WIDTH = 240;
export const DEFAULT_LARGE_WIDTH = 360;
export const COVER_MED_WIDTH = 240;
export const COVER_LARGE_WIDTH = 480;
export const COVER_MED_WIDTH_CENTER = 480;
export const COVER_LARGE_WIDTH_CENTER = 600;

export const DEFAULT_MED_HEIGHT = DEFAULT_MED_WIDTH / ASPECT_RATIO;
export const DEFAULT_LARGE_HEIGHT = DEFAULT_LARGE_WIDTH / ASPECT_RATIO;
export const COVER_MED_HEIGHT = COVER_MED_WIDTH / ASPECT_RATIO;
export const COVER_LARGE_HEIGHT = COVER_LARGE_WIDTH / ASPECT_RATIO;
export const COVER_MED_HEIGHT_CENTER = COVER_MED_WIDTH_CENTER / ASPECT_RATIO;
export const COVER_LARGE_HEIGHT_CENTER = COVER_LARGE_WIDTH_CENTER / ASPECT_RATIO;

export const getHeightForConstWidth = (width: number): number => width / ASPECT_RATIO;
